import "../../styles/components/appbar.component.css";
import { AppBar } from "@mui/material";
import { Dropdown } from "antd";
import { Fragment } from "react";
import { Menu } from "antd";
import { Menu as MenuIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Toolbar } from "@mui/material";
import logo from "../../assets/interface/logo.svg";

function AppBarComponent({
  catalogo,
  cotiza,
  facturacion,
  inicio,
  nosotros,
  proyectos,
  ubicanos,
}: {
  catalogo?: boolean;
  cotiza?: boolean;
  facturacion?: boolean;
  inicio?: boolean;
  nosotros?: boolean;
  proyectos?: boolean;
  ubicanos?: boolean;
}) {
  const MenuMobile = styled("div")(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  }));

  const MenuDesktop = styled("div")(({ theme }) => ({
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  }));

  const menuMobile = (
    <Menu>
      <Menu.Item key="1">
        <a
          href="/"
          className={inicio ? "item-movil-active-appbar" : "item-movil-appbar"}
        >
          <h2>Inicio</h2>
        </a>
      </Menu.Item>

      <Menu.Item key="2">
        <a
          href="/nosotros"
          className={
            nosotros ? "item-movil-active-appbar" : "item-movil-appbar"
          }
        >
          <h2>Nosotros</h2>
        </a>
      </Menu.Item>

      <Menu.Item key="3">
        <a
          href="/ubicanos"
          className={
            ubicanos ? "item-movil-active-appbar" : "item-movil-appbar"
          }
        >
          <h2>Ub&iacute;canos</h2>
        </a>
      </Menu.Item>

      <Menu.Item key="4">
        <a
          // href="/catalogo"
          href="https://www.3aamseq.com.pe/catalogo/index.html"
          className={
            catalogo ? "item-movil-active-appbar" : "item-movil-appbar"
          }
        >
          <h2>Cat&aacute;logo</h2>
        </a>
      </Menu.Item>

      <Menu.Item key="5">
        <a
          href="https://www.3aamseq.com.pe/cotizacion"
          rel="noreferrer"
          target="_blank"
          className={cotiza ? "item-movil-active-appbar" : "item-movil-appbar"}
        >
          <h2>Cotiza</h2>
        </a>
      </Menu.Item>

      <Menu.Item key="6">
        <a
          href="/proyectos"
          className={
            proyectos ? "item-movil-active-appbar" : "item-movil-appbar"
          }
        >
          <h2>Proyectos</h2>
        </a>
      </Menu.Item>

      <Menu.Item key="7">
        <a
          href="https://sfe.bizlinks.com.pe/sfeperu/public/loginAnonimo.jsf"
          rel="noreferrer"
          target="_blank"
          className={
            facturacion ? "item-movil-active-appbar" : "item-movil-appbar"
          }
        >
          <h2>Facturaci&oacute;n Electr&oacute;nica</h2>
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <AppBar position="sticky" className="appbar">
        <Toolbar>
          <img
            src={logo}
            alt="3AAMSEQ"
            title="3AAMSEQ"
            className="logo-appbar"
          />
          <MenuDesktop>
            <h2 className={inicio ? "item-active-appbar" : "item-appbar"}>
              <a href="/">Inicio</a>
            </h2>
            <h2 className={nosotros ? "item-active-appbar" : "item-appbar"}>
              <a href="/nosotros">Nosotros</a>
            </h2>
            <h2 className={ubicanos ? "item-active-appbar" : "item-appbar"}>
              <a href="/ubicanos">Ub&iacute;canos</a>
            </h2>
            <h2 className={catalogo ? "item-active-appbar" : "item-appbar"}>
              {/* <a href="/catalogo">Cat&aacute;logo</a> */}
              <a href="https://www.3aamseq.com.pe/catalogo/index.html">Cat&aacute;logo</a>
            </h2>
            <h2 className="item-appbar">
              <a
                href="https://www.3aamseq.com.pe/cotizacion"
                rel="noreferrer"
                target="_blank"
              >
                Cotiza
              </a>
            </h2>
            <h2 className={proyectos ? "item-active-appbar" : "item-appbar"}>
              <a href="/proyectos">Proyectos</a>
            </h2>
            <h2 className={facturacion ? "item-active-appbar" : "item-appbar"}>
              <a
                href="https://sfe.bizlinks.com.pe/sfeperu/public/loginAnonimo.jsf"
                rel="noreferrer"
                target="_blank"
              >
                Facturaci&oacute;n Electr&oacute;nica
              </a>
            </h2>
          </MenuDesktop>
          <MenuMobile>
            <Dropdown
              overlay={menuMobile}
              trigger={["click"]}
              placement="bottomRight"
            >
              <MenuIcon />
            </Dropdown>
          </MenuMobile>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}

export default AppBarComponent;
