import axios from "axios";
import Global from "../global/global";
import { APIResponse } from "../models/api_response";

interface APIConfig {
  body?: any;
  query?: any;
  auth?: boolean;
}

export class API {
  private baseUrl: string = Global.URL_API;
  constructor(baseUrl: string) {
    this.baseUrl += baseUrl;
  }

  protected async get(
    url: string,
    config?: APIConfig
  ): Promise<APIResponse<any>> {
    const { query = {}, auth = false } = config || {};
    const headers: any = { "Content-Type": "application/json" };
    if (auth) {
      headers.Authorization = `Bearer ${this.getToken()}`;
    }
    let strQuery = "";
    const keys = Object.keys(query);
    keys.forEach((element: string, _index: number) => {
      if (_index === 0) {
        strQuery = `?${element}=${query[element]}`;
      } else {
        strQuery += `&${element}=${query[element]}`;
      }
    });

    try {
      const result = await axios.get(`${this.baseUrl}${url}${strQuery}`, {
        headers: headers,
      });
      return new APIResponse<any>({ data: result.data });
    } catch (e: any) {
      // Verificamos si `e.response` existe para evitar errores de acceso a propiedades undefined
      const result = e.response?.data || { message: "Error desconocido en la API" };
      return new APIResponse<any>({ data: result, isException: true });
      // const result = e.response.data;
      // return new APIResponse<any>({ data: result, isException: true });
    }
  }

  private getToken(): string {
    const str: string = localStorage.getItem(Global.TOKEN_DATA_KEY)!;
    if (!str) {
      // return null!;
      return ""; // Retornamos un string vacío en lugar de null
    }
    const data: any = JSON.parse(str);
    // return data.access_token;
    return data?.access_token || ""; // Evitamos errores si `access_token` no existe
  }
}
